import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useFormikContext } from "formik";
import { SelectOption } from "@mui/base";
import Stack from "@mui/material/Stack";
import { RadioGroupField } from "components/formikFields/RadioGroupField/RadioGroupField";
import { TextInputField } from "components/formikFields/TextInputField/TextInputField";
import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import { SelectOneField } from "components/formikFields/SelectOneField/SelectOneField";
import { useCountryOptions } from "hooks/useCountryOptions/useCountryOptions";
import { PaymentPreferences } from "gen/clients/llts";
import { useProvinceOptions } from "hooks/useProvinceOptions/useProvinceOptions";
import { UserContactSectionFormValues } from "../UserContactSection/UserContactSection";

export enum PaymentField {
  paymentOption = "pmtPaymentOption",
  clientIdNumber = "pmtClientIdNumber",
  personalCode = "pmtPersonalCode",
  sameAddressAsContact = "pmtSameAddressAsContact",
  address1 = "pmtAddress1",
  address2 = "pmtAddress2",
  city = "pmtCity",
  state = "pmtState",
  stateName = "pmtStateName",
  country = "pmtCountry",
  countryName = "pmtCountryName",
  zip = "pmtZip",
  email = "pmtEmail",
  phone = "pmtPhone"
}

export interface PaymentFormValues {
  [PaymentField.paymentOption]: PaymentPreferences.paymentType | "";
  [PaymentField.clientIdNumber]: string;
  [PaymentField.personalCode]: string;
  [PaymentField.address1]: string;
  [PaymentField.address2]: string;
  [PaymentField.city]: string;
  [PaymentField.state]: string;
  [PaymentField.stateName]: string;
  [PaymentField.country]: string;
  [PaymentField.countryName]: string;
  [PaymentField.zip]: string;
  [PaymentField.email]: string;
  [PaymentField.phone]: string;
}

const paymentOptions: SelectOption<string>[] = [
  {
    label: "Credit Card",
    value: PaymentPreferences.paymentType.CREDIT_CARD
  },
  {
    label: "Bill to my LanguageLine Solutions Account",
    value: PaymentPreferences.paymentType.BILL_TO_ACCOUNT
  }
  /* Removed per LLTS-2041
  {
    label: "Invoice Directly",
    value: PaymentPreferences.paymentType.INVOICE_DIRECTLY
  }
*/
];

const PaymentSection: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<PaymentFormValues & UserContactSectionFormValues>();
  const { countryOptions, isCountriesLoading } = useCountryOptions();
  const country = values[PaymentField.country];
  const { provinceOptions, isProvincesLoading, provincesError } = useProvinceOptions(country ? +country : undefined);

  const state = values[PaymentField.state];
  React.useEffect(() => {
    const contactProvince = provinceOptions.find(p => p.value === +state);
    setFieldValue(PaymentField.stateName, contactProvince?.label as string);
  }, [state, provinceOptions, setFieldValue]);

  React.useEffect(() => {
    const contactCountry = countryOptions.find(c => c.value === +country);
    setFieldValue(PaymentField.countryName, contactCountry?.label as string, false);
  }, [country, countryOptions, setFieldValue]);

  return (
    <>
      <Box>
        <RadioGroupField name={PaymentField.paymentOption} options={paymentOptions} required={true} />
        <Box>
          {values[PaymentField.paymentOption] === PaymentPreferences.paymentType.CREDIT_CARD && (
            <Stack spacing={0}>
              <Typography variant="body2">
                If you choose this option, you will receive the Payment Information required to use our{" "}
                <i>Online Bill Pay</i> system.
              </Typography>
              <Card sx={{ mt: 2 }}>
                <CardHeader title="Billing Address" sx={{ pb: 0 }} titleTypographyProps={{ variant: "h6", pb: 0 }} />
                <CardContent>
                  <Grid container={true} spacing={3}>
                    <Grid item={true} xs={12}>
                      <TextInputField name={PaymentField.address1} label="Address 1" required />
                    </Grid>
                    <Grid item={true} xs={12}>
                      <TextInputField name={PaymentField.address2} label="Address 2" />
                    </Grid>
                    <Grid item={true} xs={12} sm={6}>
                      <TextInputField name={PaymentField.city} label="City" required />
                    </Grid>
                    <Grid item={true} xs={12} sm={6}>
                      <SelectOneField
                        required
                        name={PaymentField.state}
                        label="State / Province / Region"
                        options={provinceOptions}
                        isLoading={isProvincesLoading}
                        isError={!!provincesError}
                      />
                    </Grid>
                    <Grid item={true} xs={12} sm={6}>
                      <SelectOneField
                        required
                        name={PaymentField.country}
                        label="Country"
                        options={countryOptions}
                        isLoading={isCountriesLoading}
                      />
                    </Grid>
                    <Grid item={true} xs={12} sm={6}>
                      <TextInputField required name={PaymentField.zip} label="Zip / Postal Code" />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Stack>
          )}
          {values[PaymentField.paymentOption] === PaymentPreferences.paymentType.BILL_TO_ACCOUNT && (
            <Stack spacing={3}>
              <TextInputField name={PaymentField.clientIdNumber} label="Client ID Number" required={true} />
              <TextInputField name={PaymentField.personalCode} label="Personal Code" />
            </Stack>
          )}
          {values[PaymentField.paymentOption] === PaymentPreferences.paymentType.INVOICE_DIRECTLY && (
            <Stack spacing={0}>
              <Typography variant="h5">Billing Contact</Typography>
              <Grid container={true} spacing={3}>
                <Grid item={true} xs={12}>
                  <TextInputField name={PaymentField.address1} label="Address 1" />
                </Grid>
                <Grid item={true} xs={12}>
                  <TextInputField name={PaymentField.address2} label="Address 2" />
                </Grid>
                <Grid item={true} xs={12} sm={6}>
                  <TextInputField name={PaymentField.city} label="City" />
                </Grid>
                <Grid item={true} xs={12} sm={6}>
                  <SelectOneField
                    name={PaymentField.state}
                    label="State / Province / Region"
                    options={provinceOptions}
                    isLoading={isProvincesLoading}
                    isError={!!provincesError}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6}>
                  <SelectOneField
                    name={PaymentField.country}
                    label="Country"
                    options={countryOptions}
                    isLoading={isCountriesLoading}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6}>
                  <TextInputField name={PaymentField.zip} label="Zip / Postal Code" />
                </Grid>
                <Grid item={true} xs={12} sm={6}>
                  <TextInputField name={PaymentField.email} label="Email Address" />
                </Grid>
                <Grid item={true} xs={12} sm={6}>
                  <TextInputField name={PaymentField.phone} label="Phone Number" />
                </Grid>
              </Grid>
            </Stack>
          )}
        </Box>
      </Box>
    </>
  );
};

export { PaymentSection };
