import * as React from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Checkbox,
  Container,
  CssBaseline,
  FormControl,
  FormControlLabel,
  FormHelperText
} from "@mui/material";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import Stack from "@mui/material/Stack";
import { TextInputField } from "components/formikFields/TextInputField/TextInputField";
import { LoadingButton } from "components/LoadingButton/LoadingButton";
import { useMutation } from "react-query";
import { Address, UserAccountsService, UserGroup } from "gen/clients/llts";
import { SnackbarApiError } from "../../../../components/SnackbarApiError/SnackbarApiError";
import { usePasswordValidator } from "../../../../hooks/usePasswordValidator/usePasswordValidator";
import { SubmitType } from "../CreateProjectForm/CreateProjectForm";

interface Props {
  submitType: SubmitType;
  signUpData?: {
    clientId: string;
    email: string;
    firstName: string;
    lastName: string;
    mailingAddress: Address;
  };
  bannerUrl?: string;
}

enum FieldNames {
  password = "password",
  repeatPassword = "repeatPassword",
  agreementChk = "agreementChk"
}

const RequestSubmitted: React.FC<Props> = ({ submitType, signUpData, bannerUrl }) => {
  const { t } = useTranslation();
  const { validatePassword } = usePasswordValidator();
  const {
    mutate,
    isLoading,
    isSuccess: isAccountCreateSuccess,
    error: accountCreateError
  } = useMutation({
    mutationFn: UserAccountsService.creatUserAccount
  });
  const validate = React.useCallback(
    values => {
      const errors: Record<string, string> = {};
      // noinspection DuplicatedCode
      const password = values[FieldNames.password];
      const passwordError = validatePassword(password);
      if (passwordError) {
        errors[FieldNames.password] = passwordError;
      }
      if (password !== values[FieldNames.repeatPassword]) {
        errors[FieldNames.repeatPassword] = t("signUp.repeatPassword.validationMsg");
      }
      if (!values[FieldNames.agreementChk]) {
        errors[FieldNames.agreementChk] = t("common.validation.required");
      }
      return errors;
    },
    [t, validatePassword]
  );
  const onSubmit = React.useCallback(
    values => {
      const defaultAddress = {} as Address;
      mutate({
        requestBody: {
          clientId: signUpData?.clientId || "",
          email: signUpData?.email || "",
          firstName: signUpData?.firstName || "",
          lastName: signUpData?.lastName || "",
          address: signUpData?.mailingAddress || defaultAddress,
          password: values[FieldNames.password] as string,
          role: UserGroup.CLIENT_EMPLOYEE
        }
      });
    },
    [
      mutate,
      signUpData?.clientId,
      signUpData?.email,
      signUpData?.firstName,
      signUpData?.lastName,
      signUpData?.mailingAddress
    ]
  );

  return (
    <div>
      <CssBaseline />
      <Container maxWidth="md">
        {bannerUrl && (
          <img
            src={bannerUrl}
            alt={t("createProject.formHeader.banner.alt")}
            style={{ width: "100%", marginTop: "3rem", marginBottom: "2rem" }}
          />
        )}

        {!isAccountCreateSuccess && (
          <Alert severity="success" sx={{ mt: 5, width: "100%" }}>
            <AlertTitle>{t("createProject.requestSubmitted.title")}</AlertTitle>
            {submitType === SubmitType.PROJECT && (
              <>
                <p>{t("createProject.requestSubmitted.translationRequest.paragraph1")}</p>
                <p>{t("createProject.requestSubmitted.translationRequest.paragraph2")}</p>
              </>
            )}
            {submitType === SubmitType.ESTIMATE && (
              <>
                <p>{t("createProject.requestSubmitted.quoteRequest.paragraph1")}</p>
                <p>{t("createProject.requestSubmitted.quoteRequest.paragraph2")}</p>
              </>
            )}
            <p>
              <Link to="/">{t("createProject.requestSubmitted.returnHome")}</Link>
            </p>
          </Alert>
        )}

        {signUpData && (
          <div>
            {accountCreateError && <SnackbarApiError error={accountCreateError} />}
            {isAccountCreateSuccess && (
              <Alert severity="success" sx={{ mt: 5, width: "100%" }}>
                {t("createProject.requestSubmitted.signUpPrompt.emailVerificationSent")}
              </Alert>
            )}
            {!isAccountCreateSuccess && (
              <Stack
                spacing={3}
                sx={{
                  marginTop: 5,
                  alignItems: "center"
                }}
              >
                <Box>
                  <p>{t("createProject.requestSubmitted.signUpPrompt.paragraph1")}</p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t("createProject.requestSubmitted.signUpPrompt.paragraph2", { email: signUpData.email })
                    }}
                  />
                </Box>
                <Formik
                  initialValues={{
                    [FieldNames.password]: "",
                    [FieldNames.repeatPassword]: "",
                    [FieldNames.agreementChk]: false
                  }}
                  validate={validate}
                  onSubmit={onSubmit}
                >
                  {({ errors, handleChange }) => (
                    <Form noValidate={true} autoComplete="off" autoCorrect="off">
                      <Container maxWidth="xs">
                        <Stack spacing={2}>
                          <TextInputField
                            name={FieldNames.password}
                            label={t("createProject.requestSubmitted.signUpPrompt.password")}
                            type="password"
                            required={true}
                            disabled={isLoading}
                            autoComplete="off"
                          />
                          <TextInputField
                            name={FieldNames.repeatPassword}
                            label={t("createProject.requestSubmitted.signUpPrompt.repeatPassword")}
                            type="password"
                            required={true}
                            disabled={isLoading}
                            autoComplete="off"
                          />
                          <FormControl error>
                            <FormControlLabel
                              name={FieldNames.agreementChk}
                              control={<Checkbox value="agreement" color="primary" />}
                              onChange={handleChange}
                              label={t("signUp.agreement") as string}
                              sx={{ alignItems: "start" }}
                              componentsProps={{ typography: { fontSize: 12 } }}
                              disabled={isLoading}
                            />
                            {errors[FieldNames.agreementChk] && (
                              <FormHelperText>{errors[FieldNames.agreementChk]}</FormHelperText>
                            )}
                          </FormControl>

                          <LoadingButton isLoading={isLoading} fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                            {t("createProject.requestSubmitted.signUpPrompt.signUp")}
                          </LoadingButton>
                        </Stack>
                      </Container>
                    </Form>
                  )}
                </Formik>
              </Stack>
            )}
          </div>
        )}
      </Container>
    </div>
  );
};

export { RequestSubmitted };
