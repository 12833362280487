import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { Form, Formik } from "formik";
import { JobRequestsService, PaymentPreferences } from "gen/clients/llts";
import { LoadingButton } from "components/LoadingButton/LoadingButton";
import { DEFAULT_COUNTRY_CODE } from "utils/constants";
import Typography from "@mui/material/Typography";
import { useMutation } from "react-query";
import { ApiErrorMessage } from "components/ApiErrorMessage/ApiErrorMessage";
import {
  PaymentField,
  PaymentFormValues,
  PaymentSection
} from "../../../CreateProjectPage/components/PaymentSection/PaymentSection";

interface Props {
  jobRequestId: string;
  onClose: () => void;
  onSuccess: () => void;
}

const PaymentDialog: React.FC<Props> = ({ jobRequestId, onClose, onSuccess }) => {
  const { mutateAsync, error } = useMutation(JobRequestsService.acceptAutoQuote);

  const onSubmit = async (values: PaymentFormValues) => {
    const paymentType = values[PaymentField.paymentOption];
    let paymentPreferences: PaymentPreferences;
    switch (paymentType) {
      case PaymentPreferences.paymentType.CREDIT_CARD: {
        paymentPreferences = {
          paymentType: PaymentPreferences.paymentType.CREDIT_CARD,
          billingContact: {
            address1: values[PaymentField.address1].trim() || undefined,
            address2: values[PaymentField.address2].trim() || undefined,
            city: values[PaymentField.city].trim() || undefined,
            province: values[PaymentField.state]
              ? {
                  id: +values[PaymentField.state],
                  name: values[PaymentField.stateName]
                }
              : undefined,
            country: values[PaymentField.country]
              ? {
                  id: +values[PaymentField.country],
                  name: values[PaymentField.countryName]
                }
              : undefined,
            zipCode: values[PaymentField.zip].trim() || undefined
          }
        };
        break;
      }
      case PaymentPreferences.paymentType.BILL_TO_ACCOUNT: {
        paymentPreferences = {
          paymentType: PaymentPreferences.paymentType.BILL_TO_ACCOUNT,
          billingAccount: {
            clientIdNumber: values[PaymentField.clientIdNumber],
            personalCode: values[PaymentField.personalCode]
          }
        };
        break;
      }
      case PaymentPreferences.paymentType.INVOICE_DIRECTLY: {
        paymentPreferences = {
          paymentType: PaymentPreferences.paymentType.INVOICE_DIRECTLY,
          billingContact: {
            email: values[PaymentField.email].trim() || undefined,
            phoneNumber: values[PaymentField.phone],
            address1: values[PaymentField.address1].trim() || undefined,
            address2: values[PaymentField.address2].trim() || undefined,
            city: values[PaymentField.city].trim() || undefined,
            province: values[PaymentField.state]
              ? {
                  id: +values[PaymentField.state],
                  name: values[PaymentField.stateName]
                }
              : undefined,
            country: values[PaymentField.country]
              ? {
                  id: +values[PaymentField.country],
                  name: values[PaymentField.countryName]
                }
              : undefined,
            zipCode: values[PaymentField.zip].trim() || undefined
          }
        };
        break;
      }
      default: {
        throw new Error(`Unknown payment type: ${paymentType}`);
      }
    }
    await mutateAsync(
      {
        jobRequestId,
        requestBody: {
          paymentPreferences
        }
      },
      { onSuccess }
    );
  };

  return (
    <Dialog open={true} onClose={onClose} fullWidth={true} maxWidth="md">
      <DialogTitle>Payment Options</DialogTitle>
      <Formik
        validateOnBlur={false}
        validateOnMount={false}
        initialValues={{
          [PaymentField.paymentOption]: "",
          [PaymentField.clientIdNumber]: "",
          [PaymentField.personalCode]: "",
          [PaymentField.sameAddressAsContact]: [],
          [PaymentField.address1]: "",
          [PaymentField.address2]: "",
          [PaymentField.city]: "",
          [PaymentField.state]: "",
          [PaymentField.stateName]: "",
          [PaymentField.country]: DEFAULT_COUNTRY_CODE,
          [PaymentField.countryName]: "",
          [PaymentField.zip]: "",
          [PaymentField.email]: "",
          [PaymentField.phone]: ""
        }}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form noValidate={true}>
            <Stack spacing={2} mx={3}>
              <Typography>Please select your preferred payment option:</Typography>
              <PaymentSection />
              {error && <ApiErrorMessage apiError={error} />}
            </Stack>
            <DialogActions sx={{ justifyContent: "center", mt: 2, mb: 1 }}>
              <LoadingButton isLoading={isSubmitting}>Submit</LoadingButton>
              <Button color="secondary" onClick={onClose}>
                Cancel
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export { PaymentDialog };
